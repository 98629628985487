import React from 'react';
import { Container, Typography, Box, TextField, Button, Paper } from '@mui/material';

const LoginPage = () => {
  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          登入
        </Typography>
        <Box component="form" sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="電子郵件"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="密碼"
            type="password"
            variant="outlined"
            margin="normal"
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
            登入
          </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button color="primary">忘記密碼？</Button>
            <Button color="primary" component="a" href="/signup">註冊</Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;

