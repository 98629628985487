import React from 'react';
import { Container, TextField, Card, CardContent, Typography, Grid, Box, IconButton, Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalBarIcon from '@mui/icons-material/LocalBar';

const dummyOffers = [
  {
    id: 1,
    title: '20% 折扣 - 餐廳優惠',
    description: '在我們的合作餐廳享受 20% 的折扣。',
    imageUrl: 'https://via.placeholder.com/200x100?text=優惠$1'
  },
  {
    id: 2,
    title: '買一送一 - 咖啡廳優惠',
    description: '在指定咖啡廳買一杯飲料送一杯。',
    imageUrl: 'https://via.placeholder.com/250x150?text=優惠2'
  },
  {
    id: 3,
    title: '免費甜點 - 速食優惠',
    description: '購買套餐可免費獲得甜點一份。',
    imageUrl: 'https://via.placeholder.com/250x150?text=優惠3'
  },
  {
    id: 4,
    title: '特價酒水 - 酒吧優惠',
    description: '特定酒吧的酒水優惠價格。',
    imageUrl: 'https://via.placeholder.com/250x150?text=優惠4'
  },
  {
    id: 5,
    title: '週末特惠 - 餐廳優惠',
    description: '週末期間享受特惠套餐。',
    imageUrl: 'https://via.placeholder.com/250x150?text=優惠5'
  }
];

const HomePage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="搜尋餐廳或優惠..."
        sx={{ mb: 4 }}
      />
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            類別選擇
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton color="primary">
                  <LocalDiningIcon fontSize="large" />
                </IconButton>
                <Typography variant="body2">餐廳</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton color="primary">
                  <LocalCafeIcon fontSize="large" />
                </IconButton>
                <Typography variant="body2">咖啡廳</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton color="primary">
                  <FastfoodIcon fontSize="large" />
                </IconButton>
                <Typography variant="body2">速食</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton color="primary">
                  <LocalBarIcon fontSize="large" />
                </IconButton>
                <Typography variant="body2">酒吧</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={3}>
            <Button variant="contained" color="primary" startIcon={<LocalDiningIcon />}>所有分類</Button>
          </Box>
        </CardContent></Card>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          熱門優惠
        </Typography>
        <Swiper spaceBetween={10} slidesPerView={2.4} navigation pagination={{ clickable: true }} scrollbar={{ draggable: true }} style={{ paddingBottom: '20px' }}>
          {dummyOffers.map((offer) => (
            <SwiperSlide key={offer}><Card sx={{ minWidth: 200, maxWidth: 200, mx: 'auto' }}> 
              <CardContent>
                <img
                  src={offer.imageUrl}
                  alt={offer.title}
                  style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {offer.description}
                </Typography>
              </CardContent>
            </Card></SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Container>
  );
};

export default HomePage;


