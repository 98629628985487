import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, IconButton, Badge, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { Link } from 'react-router-dom';
import QRCodePage from './QRCodePage';
import CouponDetails from './CouponDetails';
import Restaurant from './Restaurant';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';

const Coupons = () => {
  // Placeholder coupons data
  const coupons = [
    { id: 1, title: '意大利美食享 20% 折扣', description: '在指定的意大利餐廳享 20% 折扣。', likes: 5, imageUrl: 'https://placehold.co/600x400' },
    { id: 2, title: '買一送一', description: '在合作地點購買一份餐點，免費獲得另一份。', likes: 3, imageUrl: 'https://placehold.co/600x400' },
    { id: 3, title: '壽司享 10% 折扣', description: '在我們的合作壽司餐廳享 10% 折扣。', likes: 8, imageUrl: 'https://placehold.co/600x400' },
  ];

  const [favorites, setFavorites] = useState({});
  const [likes, setLikes] = useState({});
  const [openCouponDetailDialog, setOpenCouponDetailDialog] = useState(false);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [openRestaurantDialog, setOpenRestaurantDialog] = useState(false);

  const handleFavoriteToggle = (id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  };

  const handleLikeToggle = (id) => {
    setLikes((prevLikes) => ({
      ...prevLikes,
      [id]: !prevLikes[id],
    }));
  };

  const handleVisibilityClick = (coupon) => {
    setSelectedCoupon(coupon);
    setOpenCouponDetailDialog(true);
  };

  const handleDialogClose = () => {
    setOpenCouponDetailDialog(false);
    setSelectedCoupon(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        可用優惠券
      </Typography>
      <Grid container spacing={3}>
        {coupons.map((coupon) => (
          <Grid item key={coupon.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column',  backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" gutterBottom sx={{ height: 200, textAlign: 'right', borderRadius: 2, padding: 2, color: 'white', backgroundImage: `url(${coupon.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                  onClick={() => setOpenQRCodeDialog(coupon)}>
                  <span style={{ backgroundColor:'black', padding:8, borderRadius: 8 }}>
                  {coupon.title}
                  </span>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {coupon.description}
                </Typography>
              </CardContent>
              <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                <IconButton
                  color="primary"
                  onClick={() => handleFavoriteToggle(coupon.id)}
                >
                  {favorites[coupon.id] ? (
                    <StarIcon sx={{ color: 'primary.main' }} />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
                <Badge badgeContent={coupon.likes + (likes[coupon.id] ? 1 : 0)} color="secondary"  >
                  <IconButton
                    color="primary"
                    onClick={() => handleLikeToggle(coupon.id)}
                  >
                    {likes[coupon.id] ? (
                      <ThumbUpIcon sx={{ color: 'primary.main' }} />
                    ) : (
                      <ThumbUpOffAltIcon />
                    )}
                  </IconButton>
                </Badge>
                <IconButton color="primary" onClick={() => setOpenQRCodeDialog(coupon)}>
                  <QrCodeOutlinedIcon />
                </IconButton>                
                <IconButton color="primary" onClick={() => handleVisibilityClick(coupon)}>
                  <VisibilityOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => setOpenRestaurantDialog(coupon)}>
                  <LocationOnOutlinedIcon />
                </IconButton>

              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openCouponDetailDialog} onClose={handleDialogClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { width: '95%', height: '95%', maxWidth: 'none' } }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{selectedCoupon?.title}</span>
          <IconButton edge="end" color="inherit" onClick={handleDialogClose}>
            <span style={{ fontSize: '1.5rem', cursor: 'pointer' }}>✖</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CouponDetails coupon={selectedCoupon} />
        </DialogContent>
      </Dialog>
      <Dialog open={openQRCodeDialog} onClose={() => setOpenQRCodeDialog(false)} maxWidth="md" fullWidth fullWidth sx={{ '& .MuiDialog-paper': { width: '95%', height: '95%', maxWidth: 'none' } }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>QR Code</span>
          <IconButton edge="end" color="inherit" onClick={() => setOpenQRCodeDialog(false)}>
            <span style={{ fontSize: '1.5rem', cursor: 'pointer' }}>✖</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <QRCodePage />
        </DialogContent>
      </Dialog>
      <Dialog open={openRestaurantDialog} onClose={() => setOpenRestaurantDialog(false)} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { width: '95%', height: '95%', maxWidth: 'none' } }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Restaurant</span>
          <IconButton edge="end" color="inherit" onClick={() => setOpenRestaurantDialog(false)}>
            <span style={{ fontSize: '1.5rem', cursor: 'pointer' }}>✖</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Restaurant />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Coupons;



