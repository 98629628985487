import React from 'react';
import { Typography, BottomNavigation, BottomNavigationAction, Box, Drawer, AppBar, Toolbar, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Coupons from '../pages/Coupons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const BottomNavigationBar = () => {
  const [value, setValue] = React.useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
      <BottomNavigation
        sx={{ backgroundColor: '#f0f0f0' }}
        value={value}
        onChange={(event, newValue) => 
          setValue(newValue)
        }
      >
        <BottomNavigationAction label="首頁" icon={<HomeIcon />} />
        <BottomNavigationAction label="優惠券" icon={<LocalOfferIcon />} onClick={() => setIsDrawerOpen(true)} />
        <BottomNavigationAction label="收藏" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="個人資料" icon={<AccountCircleIcon />} />
      </BottomNavigation>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} PaperProps={{ sx: { width: '100vw' } }}>
        <AppBar position="static" sx={{ backgroundColor: 'black' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setIsDrawerOpen(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ ml: 2 }}>
              優惠券
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '95vh', overflow: 'auto' }}>
          <Coupons isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default BottomNavigationBar;

