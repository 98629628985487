// pages/CouponDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

const CouponDetails = () => {
  const { id } = useParams();
  // Placeholder data for coupon details
  const coupon = { id, title: '20% Off Italian Cuisine', description: 'Get 20% off at selected Italian restaurants.' };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {coupon.title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {coupon.description}
      </Typography>
      <Button variant="contained" color="primary">
        Redeem Coupon
      </Button>
    </Container>
  );
};

export default CouponDetails;

