import React from 'react';
import { Container, Typography, Box, Avatar, Button, Paper, Grid, TextField } from '@mui/material';

const ProfilePage = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ width: 100, height: 100, mb: 2 }}>P</Avatar>
          <Typography variant="h5" gutterBottom>
            個人資料
          </Typography>
          <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="姓名"
                  variant="outlined"
                  defaultValue="王小明"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="電子郵件"
                  variant="outlined"
                  defaultValue="xiaoming@example.com"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="電話號碼"
                  variant="outlined"
                  defaultValue="123-456-7890"
                />
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" sx={{ mt: 3 }}>
              儲存變更
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProfilePage;

