import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import BottomNavigationBar from './components/BottomNavbar';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import Coupons from './pages/Coupons';
import CouponDetails from './pages/CouponDetails';
import Favorites from './pages/Favorites';
import NotFound from './pages/NotFound';
import Location from './pages/Restaurant';
import QRCodePage from './pages/QRCodePage';


function App() {
  return (
    <Router>
      <CssBaseline />
      <Navbar />
      <div style={{ margin: '20px 0' }}></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/coupons" element={<Coupons  />} />
        <Route path="/coupons/:id" element={<CouponDetails />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/location" element={<Location />} />
        <Route path="/qr-code" element={<QRCodePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <BottomNavigationBar  />
    </Router>
  );
}

export default App;


