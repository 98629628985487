import React from 'react';
import { Container, Typography, Box, Paper, Card, CardContent } from '@mui/material';
import QRCode from 'react-qr-code';

const QRCodePage = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          掃描二維碼
        </Typography>
        <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3, mb: 2 }}>
          <QRCode value="https://www.example.com" size={200} />
        </Card>
        <CardContent>
          <Typography variant="body1" align="center" color="textSecondary">
            此二維碼連結到我們的官方網站以獲取更多資訊。只需使用您的行動裝置掃描此碼即可開始。
          </Typography>
        </CardContent>
      </Paper>
    </Container>
  );
};

export default QRCodePage;

