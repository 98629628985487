import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, MenuItem, Box, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const isLoggedIn = false; // Replace this with actual authentication logic
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <MenuItem component={Link} to="/" onClick={handleDrawerToggle}>
        首頁
      </MenuItem>
      <MenuItem component={Link} to="/coupons" onClick={handleDrawerToggle}>
        優惠券
      </MenuItem>
      <MenuItem component={Link} to="/favorites" onClick={handleDrawerToggle}>
        收藏
      </MenuItem>
      <MenuItem component={Link} to="/profile" onClick={handleDrawerToggle}>
        個人資料
      </MenuItem>
      <MenuItem component={Link} to="/login" onClick={handleDrawerToggle}>
        登入
      </MenuItem>
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            優惠券應用程式
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button color="inherit" component={Link} to="/">首頁</Button>
            <Button color="inherit" component={Link} to="/coupons">優惠券</Button>
            <Button color="inherit" component={Link} to="/favorites">收藏</Button>
            <Button color="inherit" component={Link} to="/profile">個人資料</Button>
            {isLoggedIn ? (
              <IconButton color="inherit" component={Link} to="/profile">
                <Avatar alt="User Avatar">U</Avatar>
              </IconButton>
            ) : (
              <Button color="inherit" component={Link} to="/login">登入</Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navbar;

