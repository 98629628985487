import React from 'react';
import { Container, Typography, Box, Grid, Paper, TextField, Button, Card, CardMedia, CardContent, BottomNavigation, BottomNavigationAction } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const RestaurantPage = () => {
  const defaultProps = {
    center: {
      lat: 40.748817,
      lng: -73.985428,
    },
    zoom: 11,
  };

  const [value, setValue] = React.useState(0);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        餐廳資訊
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ height: '300px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'YOUR_GOOGLE_MAPS_API_KEY' }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            />
          </Paper>
          <Typography variant="body1" sx={{ mt: 2 }}>
            地址: 123 Main St, New York, NY 10001
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            聯繫方式: (123) 456-7890
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            圖片展示
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://via.placeholder.com/150"
                  alt="Restaurant Image 1"
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://via.placeholder.com/150"
                  alt="Restaurant Image 2"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            客戶評論
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="body1" gutterBottom>
              "這家餐廳的食物非常美味，服務也很好！" - 王小明
            </Typography>
            <Typography variant="body1" gutterBottom>
              "環境非常好，非常適合聚餐。" - 李小紅
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            可用優惠券
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="body1">
              20% 折扣優惠券 - 僅限午餐
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              查看詳情
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="首頁" icon={<HomeIcon />} />
          <BottomNavigationAction label="優惠券" icon={<LocalOfferIcon />} />
          <BottomNavigationAction label="收藏" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="個人資料" icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </Box>
    </Container>
  );
};

export default RestaurantPage;

